<template>
    <BoxLayout>
        <div class="alert alert-warning" role="alert" v-if="alertMessage">{{alertMessage}}</div>
        <form v-on:submit.prevent="submit" novalidate v-if="!isRequestComplete">
            <div class="mb-3">
                <TextInput
                    label="Email address"
                    v-model="v$.email.$model"
                    :v="v$.email"
                    type="email"
                />
            </div>
            <button type="submit" class="btn w-100 btn-primary">Submit</button>
        </form>
        <div v-if="isRequestComplete" class="text-center">
            <p>If an account is found matching the email address provided, you will receive an email with a link to reset your password.</p>
            <p>If you do not receive this email in the next few minutes, please check your spam/junk folder.</p>
        </div>
    </BoxLayout>
</template>

<script>
    import { ref } from 'vue';
    import api from "@/services/api";
    import TextInput from '@/components/TextInput.vue';
    import BoxLayout from '@/components/BoxLayout.vue';
    import useVuelidate from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

    export default {
        data() {
            return {
                email: '',
                alertMessage: null,
                isRequestComplete: false,
            }
        },
        setup () {
            const externalResults = ref({});
            return {
                externalResults,
                v$: useVuelidate({ $externalResults: externalResults }),
            }
        },
        validations () {
            return {
                email: { required, email },
            }
        },
        components: {            
            TextInput,
            BoxLayout,
        },
        methods: {
            submit: async function () {
                this.alertMessage = '';
                this.isRequestComplete = false;

                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.requestCookie();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            requestCookie: function()
            {
                api
                .get('/sanctum/csrf-cookie')
                .then(() => {
                  this.request();
                })
                .catch(() => {
                  this.alertMessage = 'There was an problem requesting a password reset. Please try again later.';
                  this.isProcessing = false;
                });
            },
            request: function () {
                api.post('/api/v1/password-reset', {
                    email: this.email,
                })
                .then(() => {
                    this.isRequestComplete = true;
                })
                .catch((error) => {
                    if (error.response)
                    {
                        if(error.response.data.message)
                        {
                            Object.assign(this.externalResults, { email: error.response.data.message});
                        }
                        else
                        {
                            Object.assign(this.externalResults, error.response.data.errors);
                        }

                    } 
                    else
                    {
                        this.alertMessage = 'There was an problem resetting your password. Please try again later.';
                    }
                });
            },
        },
    }
</script>